/* src/app/routes/sales/new-sale/new-sale.component.scss */
.logo-padded {
  max-width: 256px;
  min-height: 64px;
  object-fit: none;
}
.timeline-centered {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
}
.timeline-centered:before,
.timeline-centered:after {
  content: " ";
  display: table;
}
.timeline-centered:after {
  clear: both;
}
.timeline-centered:before,
.timeline-centered:after {
  content: " ";
  display: table;
}
.timeline-centered:after {
  clear: both;
}
.timeline-centered:before {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  background: lightgray;
  top: 17px;
  bottom: 30px;
  margin-left: 27px;
}
.timeline-centered-last:before {
  width: 0px !important;
}
.timeline-centered .timeline-entry {
  position: relative;
  margin-top: 5px;
  margin-left: 30px;
  margin-bottom: 10px;
  clear: both;
}
.timeline-centered .timeline-entry:before,
.timeline-centered .timeline-entry:after {
  content: " ";
  display: table;
}
.timeline-centered .timeline-entry:after {
  clear: both;
}
.timeline-centered .timeline-entry:before,
.timeline-centered .timeline-entry:after {
  content: " ";
  display: table;
}
.timeline-centered .timeline-entry:after {
  clear: both;
}
.timeline-centered .timeline-entry.begin {
  margin-bottom: 0;
}
.timeline-centered .timeline-entry.left-aligned {
  float: left;
}
.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
  margin-left: 0;
  margin-right: -18px;
}
.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
  left: auto;
  right: -100px;
  text-align: left;
}
.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
  float: right;
}
.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
  margin-left: 0;
  margin-right: 70px;
}
.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
  left: auto;
  right: 0;
  margin-left: 0;
  margin-right: -9px;
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.timeline-centered .timeline-entry .timeline-entry-inner {
  position: relative;
  margin-left: -20px;
}
.timeline-centered .timeline-entry .timeline-entry-inner:before,
.timeline-centered .timeline-entry .timeline-entry-inner:after {
  content: " ";
  display: table;
}
.timeline-centered .timeline-entry .timeline-entry-inner:after {
  clear: both;
}
.timeline-centered .timeline-entry .timeline-entry-inner:before,
.timeline-centered .timeline-entry .timeline-entry-inner:after {
  content: " ";
  display: table;
}
.timeline-centered .timeline-entry .timeline-entry-inner:after {
  clear: both;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time {
  position: absolute;
  left: -100px;
  text-align: right;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:first-child {
  font-size: 15px;
  font-weight: bold;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:last-child {
  font-size: 12px;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
  background: #fff;
  color: #737881;
  display: block;
  width: 34px;
  height: 34px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  border-radius: 17px;
  text-align: center;
  -moz-box-shadow: 0 0 0 0px white;
  -webkit-box-shadow: 0 0 0 0px white;
  box-shadow: 0 0 0 0px white;
  line-height: 34px;
  font-size: 15px;
  float: left;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-primary {
  background-color: #303641;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-secondary {
  background-color: #ee4749;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-success {
  background-color: #00a651;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-infoo {
  background-color: #21a9e1 !important;
  color: #fff !important;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-warning {
  background-color: #fad839;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-danger {
  background-color: #cc2424;
  color: #fff;
}
.ml-60 {
  margin-left: 60px;
}
.panel-title {
  margin: 15px 15px 15px 15px;
}
.pointer {
  cursor: pointer;
}
.panel-angle {
  cursor: pointer;
  font-size: 20px;
}
.panel-title-title {
  font-weight: 600;
  margin-bottom: 0px;
}
.panel-title-body {
  font-size: 13px;
}
.bottom {
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.next-btn {
  margin: auto;
}
.mx-12 {
  margin: 0px 12px;
}
.empty {
  background-color: white !important;
  border: 1px solid !important;
  color: #21a9e1 !important;
}
.space-between {
  justify-content: space-between;
}
.form-padding-top {
  padding-top: 60px;
}
.button-line-height {
  line-height: 70px;
}
.filter-dd > .dropdown-menu {
  width: 100%;
  background-color: #f5f7fa;
}
.filter-dd > .dropdown-toggle {
  background-color: #f5f7fa;
}
.filter-dd-button {
  text-align: left;
}
.filter-dd-button::after {
  text-align: left;
}
.filter-dd > .dropdown-toggle::after {
  text-align: right;
  float: right;
  margin-top: -12px;
}
.filter-dd > .dropdown-toggle::after {
  text-align: right;
  float: right;
  margin-top: 8px;
}
.succeess {
  min-width: 100px;
}
.mt-20 {
  margin-top: 20px;
}
.continue-button {
  margin: auto;
}
.py-10 {
  padding: 10px 0px;
}
.center-title {
  margin: auto;
  font-weight: 700;
}
.header-modal {
  padding: 20px 15px;
  background: #f5f7fa;
}
.close-modal {
  position: absolute;
  right: 20px;
  font-weight: bold;
  top: 20px;
  font-size: 40px;
}
.center {
  text-align: center;
}
.center-cnt {
  padding: 5px 0px;
  justify-content: center;
}
.center-cnt-sent {
  padding: 30px 0px;
  justify-content: center;
}
.body-title-modal {
  padding: 15px 0px;
}
.body-title-modal-s {
  padding: 15px 0px 55px 0px;
}
.no-border {
  border-top: none !important;
}
.capital {
  text-transform: uppercase;
}
.ticket {
  padding: 20px;
  border-radius: 50%;
  background: rgba(16, 255, 0, 0.1882352941);
}
.ticket > i {
  background: #23ff23;
  color: white;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
}
.success-button {
  padding: 105px 0px 10px 0px;
  justify-content: center;
}
.panel-title {
  font-weight: 800;
  text-transform: none;
}
.ng-select.ng-select-focused ::ng-deep .ng-select-container:after {
  border-color: #9e9e9e !important;
}
.ng-select ::ng-deep .ng-select-container:after {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.ng-select.ng-invalid.ng-touched ::ng-deep .ng-select-container:after {
  border-bottom: 2px solid red !important;
}
.ng-select.ng-select-focused ::ng-deep .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-focused ::ng-deep .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: #9e9e9e;
}
.buttons-modal {
  padding: 30px 0px;
}
.mw140 {
  min-width: 140px;
}
.confirm-dialog-contrat {
  margin-top: 10%;
}
.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}
.margin-append {
  margin: 32px 0px 2px 0px;
  cursor: pointer;
}
.margin-append:hover {
  margin: 32px 0px 2px 0px;
  cursor: pointer;
}
.mt-25 {
  margin-top: 25px;
}
.w90 {
  width: 90%;
  margin-bottom: 0px !important;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .w90 {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .w90 {
    width: 80%;
  }
}
.py5 {
  padding: 5px 0px;
}
.table-scroll {
  width: 50%;
}
.s-thead,
.s-tbody {
  display: block;
}
.s-tbody {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.s-tbody td {
  width: 1% !important;
}
.s-thead th {
  width: 1% !important;
}
.mh-70 {
  min-height: 70vh;
}
.error {
  background: rgba(255, 61, 0, 0.2705882353) !important;
}
.error > i {
  background: #ff2323 !important;
}
.mt-6 {
  margin-top: 39px !important;
}
.mt-10-negative {
  margin-top: -10px !important;
}
.bottom-30 {
  margin-bottom: 30px;
}
.error0030.pulse > span i {
  box-shadow: 0 0 0 rgba(59, 134, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  animation: pulse 2s infinite;
}
.blank_layout.wrapper {
  background-color: #FFFFFF;
}
.width-450 {
  width: 100%;
  max-width: 450px;
}
.width-450.margin-auto {
  margin-left: auto;
  margin-right: auto;
}
.icon-row {
  justify-content: center;
}
.icon-row em {
  font-size: 30px !important;
}
.icon-row + p.text-danger {
  font-size: 20px;
  display: inline-block;
  max-width: 320px;
}
.row.align-items-center {
  height: 100%;
}
.row.row-errors {
  flex: 1;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(59, 134, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(59, 134, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(59, 134, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(59, 134, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(59, 134, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(59, 134, 255, 0);
    box-shadow: 0 0 0 10px rgba(59, 134, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(59, 134, 255, 0);
    box-shadow: 0 0 0 0 rgba(59, 134, 255, 0);
  }
}
/*# sourceMappingURL=new-sale.component.css.map */
