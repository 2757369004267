import { Component, OnInit, TemplateRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SalesService } from '../../../services/sales.service';
import { AppService } from '../../../core/services/base.service';
import { UserService } from '../../../services/user.service';
import { SellerService } from '../../../services/seller.service';
import { Router , ActivatedRoute } from '@angular/router';
import { ExternalService } from '../../../services/external.service';
import { LoggingService } from '../../../core/services/log.service'
import { TranslatorService } from '../../../core/translator/translator.service'; 
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../../core/settings/settings.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-new-sale',
  templateUrl: './new-sale.component.html',
  styleUrls: ['./new-sale.component.scss'],
  animations: [
    trigger('toggleFirstPanel', [
      state('collapsed', style({
        'height': '0px',
        'padding': '0px'
      })),
      state('expanded', style({
        'height': 'auto'
      })),/* 'height': '130px', 'margin-bottom': ' 55px' */
      transition('collapsed=>expanded', animate('150ms ease-out')),
      transition('expanded=>collapsed', animate('150ms ease-in'))
    ]),
    trigger('toggleSecondPanel', [
      state('collapsed', style({
        'height': '0px',
        'padding': '0px'
      })),
      state('expanded', style({
        'height': 'auto'
      })),/* 'height': '55px', 'margin-bottom': ' 55px' */
      transition('collapsed=>expanded', animate('150ms ease-out')),
      transition('expanded=>collapsed', animate('150ms ease-in'))
    ]),
    trigger('toggleThirdPanel', [
      state('collapsed', style({
        'height': '0px',
        'padding': '0px'
      })),
      state('expanded', style({
        'height': 'auto'
      })),/* 'height': '55px', 'margin-bottom': ' 55px' */
      transition('collapsed=>expanded', animate('150ms ease-out')),
      transition('expanded=>collapsed', animate('150ms ease-in'))
    ]),
    trigger('toggleFourthPanel', [
      state('collapsed', style({
        'height': '0px',
        'padding': '0px'
      })),
      state('expanded', style({
        'height': 'auto'
      })),/* 'height': '550px', 'margin-bottom': ' 55px' */
      transition('collapsed=>expanded', animate('150ms ease-out')),
      transition('expanded=>collapsed', animate('150ms ease-in'))
    ]),
    trigger('toggleFifthPanel', [
      state('collapsed', style({
        'height': '0px',
        'padding': '0px'
      })),
      state('expanded', style({
        'height': 'auto'      })),/* 'height': '550px', 'margin-bottom': ' 55px' */
      transition('collapsed=>expanded', animate('150ms ease-out')),
      transition('expanded=>collapsed', animate('150ms ease-in'))
    ]),
  ]
})

export class NewSaleComponent implements OnInit {

  selectedRate:any;
  shownTable: any;
  modalRef: BsModalRef;
  sentContrat: Boolean;
  errorOnSendContrat:Boolean
  clients: any = []
  selectedInstallment: any;
  selectedClient: any;
  rates: any = [];
  monthsCalculations: any = []
  expandedData: any = {
    "clientData": {
      "email": "",
      "name": "",
      "surname": "",
      "phone": "",
      "language": "",
    },
    "paymentServiceData": {
      "name": ""
    },
    "paymentTypeData": {
      "installment": {
        "id": null,
        "name": null,
      },
      "import": "",
    },
    "installmentData": {
      "profile": "",
      "instalmentNumber": "",
      "instalmentFrequency": "",
      "startDelay": "",
      "firstInstalmentType": "",
      "renew": ""
    },
  };
  merchantDetail : any = [];
  idMerchant;
  createdSaleData : any;
  linkFirstInstallment : string = '';
  showError0030 : boolean = false;
  isExternalSale : boolean = false;
  extSaleToken : any;
  editInstalmentNumber : any;
  extSaleData: any;
  extSaleRedirectUrl: string;
  validExtSale : boolean = false;
  showError : boolean = false;
  isRateModificabile : boolean = true;
  isPrimaRataModificabile : boolean = true;
  defaultFirstInstalmentType : any = [];
  max_startInstalment : number;
  max_digit : number;
  _err = false

  langList: any;

  config = {
    ignoreBackdropClick: false
  }

  instalmentNumbers = [
    { number: 1 },
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
    { number: 6 },
    { number: 7 },
    { number: 8 },
    { number: 9 },
    { number: 10 },
    { number: 11 },
    { number: 12 },
  ]

  states = [
    { 'state': 'expanded', 'formStatusValid': false, 'show': true },
    { 'state': 'collapsed', 'formStatusValid': false, 'show': true },
    { 'state': 'collapsed', 'formStatusValid': false, 'show': true },
    { 'state': 'collapsed', 'formStatusValid': false, 'show': false },
    { 'state': 'collapsed', 'formStatusValid': false, 'show': false }
  ];

  ratesTypes = [{
    "id": 1, "name": "SINGOLO",
  }, {
    "id": 2, "name": "RATEIZZATO",
  }, {
    "id": 3, "name": "RICORRENTE"
  }]

  frequencies = [{
    "id": 1, "nrMonths": 1, "name": "MENSILE",
  }, {
    "id": 2, "nrMonths": 2, "name": "BIMESTRALE",
  }, {
    "id": 3, "nrMonths": 3, "name": "TRIMESTRALE",
  }, {
    "id": 4, "nrMonths": 6, "name": "SEMESTRALE",
  }, {
    "id": 5, "nrMonths": 12, "name": "ANNUALE",
  }]

  firstInstalmentTypes = [
    { "id": 1, "name": "PERCENTUALE", "value": "PERCENTUALE" },
    { "id": 2, "name": "VALORE FISSO", "value": "VALORE_FISSO" },
    { "id": 3, "name": "UNIFORME", "value": "UNIFORME" }
  ];

  firstInstalmentRecurrentTypes = [
    { "id": 2, "name": "VALORE FISSO", "value": "VALORE_FISSO" },
    { "id": 3, "name": "UNIFORME", "value": "UNIFORME" }
  ];

  schedulingPaymentDays = [
    { "id": 1, "name": "PRIMO GIORNO DEL MESE", "value": "FIRST_DAY_OF_MONTH" },
    { "id": 2, "name": "GIORNO DEL PRIMO PAGAMENTO", "value": "FIRST_PAYMENT_DAY" },
    { "id": 3, "name": "GIORNO DI PAGAMENTO DEL MERCHANT", "value": "MERCHANT_PAYMENT_DAY" }
  ]

  days=[
    {number:1},
    {number:2},
    {number:3},
    {number:4},
    {number:5},
    {number:6},
    {number:7},
    {number:8},
    {number:9},
    {number:10},
    {number:11},
    {number:12},
    {number:13},
    {number:14},
    {number:15},
    {number:16},
    {number:17},
    {number:18},
    {number:19},
    {number:20},
    {number:21},
    {number:22},
    {number:23},
    {number:24},
    {number:25},
    {number:26},
    {number:27},
    {number:28}
  ]

  expirationTypes = [
    { "id": 1, "name": "NON TASSATIVA", "value": "NOT_MANDATORY" },
    { "id": 2, "name": "TASSATIVA", "value": "MANDATORY" }
  ]

  formStatus = ['invalid', 'invalid', 'invalid', 'invalid', 'invalid'];
  clientFound: any;
  notFounded: any;
  foundedClient: any;

  clientDataForm = this.fb.group({
    email: ['', Validators.required],
    name: ['', Validators.required],
    surname: ['', Validators.required],
    phone: [''],/* , Validators.required */
    language: [null]
  });

  paymentServiceForm = this.fb.group({
    name: ['', Validators.required]
  });

  paymentTypeForm = this.fb.group({
    installment: [null, Validators.required],
    import: ['', Validators.required],
  });

  installmentsForm = this.fb.group({
    profile: [null],
    instalmentNumber: [null, Validators.required],
    instalmentFrequency: [null, Validators.required],
    instalmentPaymentDay: [null, Validators.required],
    startDelay: ['', Validators.required],
    expirationType: [null, Validators.required],
    firstInstalmentType: [null, Validators.required],
    firstInstalmentValue: [null, Validators.required]
  });

  installmentsFormBis = this.fb.group({
    // profile: [null],
    // instalmentNumber: [null, Validators.required],
    instalmentFrequency: [null, Validators.required],
    schedulingPaymentDay: [null, Validators.required],
    startDelay: ['', Validators.required],
    expirationType: [null, Validators.required],
    firstInstalmentType: [null, Validators.required],
    firstInstalmentValue: [null, Validators.required]
  });

  localRequest : any = {
    "servizio": "Supporto annuale",
    "importo": 12.6,
    "cognome": "pinco",
    "nome": "pallino",
    "mail": "pinco.pallino@world.it",
    "cellulare": "+393471234567",
    "gestionale1": "Campo libero 1",
    "gestionale2": "Campo libero 2",
    "gestionale3": "Campo libero 3",
    "urlCallbackOK": "http://www.gestionale.it/callback/esitoOK",
    "urlCallbackKO": "http://www.gestionale.it/callback/esitoKO",
    "urlServerToServer": "http://www.gestionale.it/callback/serverPage"
  };

  constructor( private appService: AppService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private saleService: SalesService,
    private userService: UserService, 
    private sellerService : SellerService,
    private router : Router,
    private route : ActivatedRoute,
    private externalService : ExternalService, 
    private logger : LoggingService,
    private translator: TranslatorService,
    private translate: TranslateService,
    private settings: SettingsService ) {
      this.langList = this.translator.getAvailableLanguages();
    }

  async ngOnInit() {
    this.extSaleToken = this.route.snapshot.params['token']
    
    if( this.extSaleToken ) {
      this.isExternalSale = true
    }

    if( this.isExternalSale == false ) {
      this.clientFound = false
      this.sentContrat = false
      this.onChangePaymentTypeForm();
      this.onChangeInstallmentForm();
      // this.getAllClients()
      if ( this.idMerchant != null ) {
        
      } else {
        this.idMerchant = localStorage.getItem("idMerchant")
      }

      await this.getMerchantDetail( this.idMerchant );
      if( this.merchantDetail.country ) {
        this.setLanguageClient( this.merchantDetail.country );
      } else {
        this.setLanguageClient( this.translate.currentLang );
      }

      this.getAllRates()
    } else {
      this.settings.clearLocalStorage();
      this.getExternalSaleData( this.extSaleToken )
    }
  }

  get firstInstalmentType() {
    return this.installmentsForm.get('firstInstalmentType').value;
  }

  get firstInstalmentRecurrentType() {
    return this.installmentsFormBis.get('firstInstalmentType').value;
  }

  onChangeInstallmentForm() {
    this.installmentsForm.valueChanges.subscribe(val => {
      this.calculateOnTable()
    })
  }

  validateAllForms() {
    this.states[0].formStatusValid = this.checkClientDataForm();
    this.states[1].formStatusValid = this.checkPaymentServiceForm();
    this.states[2].formStatusValid = this.checkPaymentTypeForm();
    this.states[3].formStatusValid = this.checkInstallmentForm();
    this.states[4].formStatusValid = this.checkInstallmentFormBis();
  }

  onChangePaymentTypeForm() {
    this.paymentTypeForm.get('import').valueChanges.subscribe(val => {
      this.calculateOnTable();
    })
    this.paymentTypeForm.get('installment').valueChanges.subscribe(val => {
      if (val) {
        this.expandedData.paymentTypeData.installment = val;
        if ( val.id == 2 ) {
          this.onChangeInstallmentForm()
          this.states[2].formStatusValid = false
          this.states[3].show = true;
          this.states[4].show = false;
          this.states[4].formStatusValid = false
          this.resetFormRicorrenze();
        } else if ( val.id == 3 ) {
          this.onChangeInstallmentForm()
          this.states[2].formStatusValid = false
          this.states[3].show = false;
          this.states[3].formStatusValid = false
          this.states[4].show = true;
          this.shownTable = false
          this.resetFormRateizzazione();
        }
        else {
          this.onChangeInstallmentForm()
          this.states[2].formStatusValid = true
          this.states[3].show = false;
          this.states[3].formStatusValid = false
          this.states[4].show = false;
          this.states[4].formStatusValid = false
          this.shownTable = false
          this.resetFormRicorrenze()
          this.resetFormRateizzazione();
        }
      }
      else {
        this.states[2].formStatusValid = false
      }
    });    
  }

  onClientDataFormChanges(): void {
    this.clientDataForm.get('email').valueChanges.subscribe(val => {
      this.notFounded = false;
      if (this.clientDataForm.get('email').value == '') {
        this.clientDataForm.get('name').disable()
        this.clientDataForm.get('surname').disable()
        this.clientDataForm.get('phone').disable()
      } else {
        this.clientDataForm.get('name').enable()
        this.clientDataForm.get('surname').enable()
        this.clientDataForm.get('phone').enable()
      }
    });
  }

  checkClientDataForm() {
    if (this.clientDataForm.status == 'VALID')
      return true;
    else
      return false
  }

  checkPaymentServiceForm() {
    if (this.paymentServiceForm.status == 'VALID')
      return true;
    else
      return false
  }

  checkInstallmentForm() {
    if (this.installmentsForm.status == 'VALID')
      return true;
    else
      return false
  }

  checkPaymentTypeForm() {
    if (this.paymentTypeForm.status == 'VALID')
      return true;
    else
      return false
  }

  checkInstallmentFormBis() {
    if (this.installmentsFormBis.status == 'VALID')
      return true;
    else
      return false
  }

  resetFormRateizzazione(){
    this.installmentsForm = this.fb.group({
      profile: [null],
      instalmentNumber: [null, Validators.required],
      instalmentFrequency: [null, Validators.required],
      instalmentPaymentDay: [null, Validators.required],
      startDelay: ['', Validators.required],
      expirationType: [null, Validators.required],
      firstInstalmentType: [null, Validators.required],
      firstInstalmentValue: [null, Validators.required]
    });
  }

  resetFormRicorrenze() {
    this.installmentsFormBis = this.fb.group({
      // profile: [null],
      // instalmentNumber: [null, Validators.required],
      instalmentFrequency: [null, Validators.required],
      schedulingPaymentDay: [null, Validators.required],
      startDelay: ['', Validators.required],
      expirationType: [null, Validators.required],
      firstInstalmentType: [null, Validators.required],
      firstInstalmentValue: [null, Validators.required]
    });
  }

  /**
   * This are function for submiting forms
   * @param index
   */

  onSubmitPaymentType() {
    // console.log( 'onSubmitPaymentType' , this.checkPaymentTypeForm() )
    this.states[2].formStatusValid = this.checkPaymentTypeForm();
    if (!this.states[2].formStatusValid) {
      for (var i in this.paymentTypeForm.controls) {
        this.paymentTypeForm.controls[i].markAsTouched();
      }
    }
    else {
      if ( this.paymentTypeForm.get("installment").value.id == 3) {
        this.next(2);
      } else {
        this.next(2);
      }
      
    }
    // console.log( 'onSubmitPaymentType' , this.paymentTypeForm.get("installment").value )
    this.expandedData.paymentTypeData.installment = this.paymentTypeForm.get("installment").value;
  }

  onSubmitClientData() {
    this.states[0].formStatusValid = this.checkClientDataForm();
    if (!this.states[0].formStatusValid) {
      for (var i in this.clientDataForm.controls) {
        this.clientDataForm.controls[i].markAsTouched();
      }
    }
    else {
      this.next(0);
    }
  }

  onSubmitPaymentService() {
    this.states[1].formStatusValid = this.checkPaymentServiceForm();
    if (!this.states[1].formStatusValid) {
      for (var i in this.paymentServiceForm.controls) {
        this.paymentServiceForm.controls[i].markAsTouched();
      }
    }
    else {
      this.next(1);
    }
  }

  onSubmitInstallmentData() {
    this.states[3].formStatusValid = this.checkInstallmentForm();
    if (!this.states[3].formStatusValid) {
      for (var i in this.installmentsForm.controls) {
        this.installmentsForm.controls[i].markAsTouched();
      }
    } else {
      this.finish();
    }
  }

  onSubmitRicorrenzaData() {
    this.states[4].formStatusValid = this.checkInstallmentFormBis();
    // console.log('onSubmitRicorrenzaData' , this.states[4].formStatusValid )
    if (!this.states[4].formStatusValid) {
      for (var i in this.installmentsFormBis.controls) {
        this.installmentsFormBis.controls[i].markAsTouched();
      }
    }
    else {
      this.finish(4);
    }
  }

  getAllClients() {
    this.saleService.getAllClients().subscribe((res: any) => {
      this.clients = res.results
      this.clients.forEach(element => {
        element['name'] += (" " + element['surname'])
      });
    })
  }

  getAllRates(params = null) {
    this.appService.getAll("/instalment-plan/getAll/" + this.idMerchant + "?enabled=true").subscribe((res: any) => {
      this.rates = res.results
    })
  }

  calculateOnTable() {
    if (this.paymentTypeForm.valid && this.installmentsForm.valid) {
      this.shownTable = false
      this.monthsCalculations = [];
      let nrInstallments = parseInt(this.installmentsForm.get('instalmentNumber').value);
      let allAmount = Number.parseFloat(this.paymentTypeForm.get('import').value).toFixed(2);
      let startDelay = parseInt(this.installmentsForm.get('startDelay').value);
      let frequency = this.installmentsForm.get('instalmentFrequency').value.name;
      let paymentDay = this.installmentsForm.get('instalmentPaymentDay').value;
      let firstMonthValue = Number.parseFloat(this.installmentsForm.get('firstInstalmentValue').value).toFixed(2);
      let instalmentType = this.installmentsForm.get('firstInstalmentType').value.value;

      let request: any = {
        "startDelay": startDelay,
        "instalmentFrequency": frequency,
        "instalmentNumber": nrInstallments,
        "instalmentPaymentDay": paymentDay,
        "firstInstalmentType": instalmentType,
        "firstInstalmentValue": firstMonthValue == "NaN" ? null : firstMonthValue,
        "saleAmount": allAmount
      };

      this.appService.getAllFromData('/instalment/calculate/standard' + (this.isExternalSale ? '?idMerchant=' + this.extSaleData.idMerchant : ''), request).subscribe((res: any) => {
        if ( res.results ) {
          let instalments = res.results;
          this.monthsCalculations = [];
          var monthsCalc = this.monthsCalculations;
          instalments.forEach(function(instalment) {
            let thisMonth: any = {};
            thisMonth.nrMonth = instalment.order;
            thisMonth.date = instalment.expiration;
            thisMonth.value = instalment.amount;
            monthsCalc.push(thisMonth);
          })
          this.shownTable = true
          this.showError0030 = false
          // this.installmentsForm.setErrors({ 'invalid': false });
        } else {
          if ( res.code === "0030" ) { 
            this.showError0030 = true
            // this.installmentsForm.setErrors({ 'invalid': true });
          }
        }
      })
    } else {
      this.shownTable = false
    }
  }

  displayDate(addedMonth) {
    var today = new Date()
    var month, year, date;

    year = today.getFullYear()
    month = today.getMonth()
    date = today.getDate()
    return this.formatDate(new Date(year, month + addedMonth, date))
  }

  formatDate(date) {
    if ((date.getMonth() + 1) < 10)
      date = date.getDate() + '/0' + (date.getMonth() + 1) + '/' + date.getFullYear();
    else
      date = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    return date;
  }

  initRateForm(rate) {
    this.selectedRate = rate;
    this.installmentsForm.get('startDelay').setValue(rate.startDelay)
    this.installmentsForm.get('instalmentNumber').setValue(rate.instalmentNumber)
    this.installmentsForm.get('instalmentFrequency').setValue(this.findItemOnArray(rate.instalmentFrequency, this.frequencies))
    this.installmentsForm.get('firstInstalmentType').setValue(this.findItemOnArrayByValue(rate.firstInstalmentType, this.firstInstalmentTypes))
    this.installmentsForm.get('firstInstalmentValue').setValue(rate.firstInstalmentValue)
    if (rate.firstInstalmentType == 'UNIFORME') {
      this.installmentsForm.get('firstInstalmentValue').reset()
      this.installmentsForm.get('firstInstalmentValue').disable()
    }
  }

  findItemOnArray(item, array) {
    for (let i = 0; i < array.length; i++) {
      if (item == array[i].name)
        return array[i]
    }
    return
  }

  findItemOnArrayByValue(item, array) {
    for (let i = 0; i < array.length; i++) {
      if (item == array[i].value)
        return array[i]
    }
    return null;
  }

  searchClient() {
    let name = this.clientDataForm.get('email').value
    if (name.length > 0) {
      this.saleService.searchForClients(this.clientDataForm.get('email').value).subscribe((response: any) => {
        if(response.success != false){
        this.clientFound = true
        this.foundedClient = response;
        this.clientDataForm.get("name").setValue(response.name)
        this.clientDataForm.get("surname").setValue(response.surname)
        this.clientDataForm.get("phone").setValue(response.phoneNumber)

        this.clientDataForm.get('name').disable()
        this.clientDataForm.get('surname').disable()
        this.clientDataForm.get('phone').disable()
        }
        else{
          this.foundedClient = null;
          this.clientFound = false
        }
      },
        error => {
          this.foundedClient = null;
          this.clientFound = false
        })
    }
  }

  clearClient() {
    this.foundedClient = null;
    this.clientFound = false
    this.clientDataForm.reset()
  }

  sendContrat() {
    let insertData: any;
    if (this.expandedData.paymentTypeData.installment.id == 2) {
      insertData = {
        service: this.expandedData.paymentServiceData.name,
        paymentType: this.expandedData.paymentTypeData.installment.name,
        saleAmount: this.expandedData.installmentData.import,
        startDelay: this.expandedData.installmentData.startDelay,
        expirationType: this.expandedData.installmentData.expirationType.value,
        instalmentFrequency: this.expandedData.installmentData.instalmentFrequency.name,
        instalmentNumber: this.expandedData.installmentData.instalmentNumber,
        instalmentPaymentDay: this.expandedData.installmentData.instalmentPaymentDay,
        firstInstalmentType: this.expandedData.installmentData.firstInstalmentType.value,
        firstInstalmentValue: this.expandedData.installmentData.firstInstalmentValue,
        language: this.expandedData.clientData.language,
      }
    } else if (this.expandedData.paymentTypeData.installment.id == 3) {
      insertData = {
        service: this.expandedData.paymentServiceData.name,
        paymentType: this.expandedData.paymentTypeData.installment.name,
        saleAmount: this.expandedData.installmentData.import,
        startDelay: this.expandedData.installmentData.startDelay,
        expirationType: this.expandedData.installmentData.expirationType.value,
        schedulingPaymentDay: this.expandedData.installmentData.schedulingPaymentDay.value,
        instalmentFrequency: this.expandedData.installmentData.instalmentFrequency.name,
        firstInstalmentType: this.expandedData.installmentData.firstInstalmentType.value,
        firstInstalmentValue: this.expandedData.installmentData.firstInstalmentValue,
        language: this.expandedData.clientData.language,
      }
    } else {
      insertData = {
        service: this.expandedData.paymentServiceData.name,
        paymentType: this.expandedData.paymentTypeData.installment.name,
        saleAmount: this.expandedData.installmentData.import,
        language: this.expandedData.clientData.language
      }
    }
    if (!this.clientFound) {
      insertData.email = this.expandedData.clientData.email;
      insertData.name = this.expandedData.clientData.name;
      insertData.surname = this.expandedData.clientData.surname;
      insertData.phoneNumber = this.expandedData.clientData.phone;
    } else {
      insertData.idCustomer = this.foundedClient.customerId;
    }

    console.log('insertData' , insertData )
    swal.showLoading();
    if(this.isExternalSale == false) {
      this.saleService.insertSale(insertData).subscribe((res: any) => {
        if(res.outcome.success) {
          this.sentContrat = true;
          if( this.merchantDetail.linkFirstPayment ) {
            this.createdSaleData = res.data;
            this.linkFirstInstallment = this.createdSaleData.linkFirstPayment
          }
        } else {
          this.errorOnSendContrat = true
        }
        swal.close()
      })
    } else {
      let idInstalment : string = '';
      this.appService.newElement('/gestionale/vendita/make/' + this.extSaleToken , insertData).subscribe((res:any)=>{
        if(res.outcome.success){
          this.sentContrat = true;
          idInstalment = res.data.linkFirstPayment   
          this.navigateToPayment( idInstalment )       
        } else {
          this.errorOnSendContrat = true
        }
        //this.finalizeExternaleSale( this.extSaleToken , this.sentContrat , this.navigateToPayment , idInstalment )
      })
    }
  }

  next(index) {
    this.states[index].state = this.states[index].state === 'collapsed' ? 'expanded' : 'collapsed';
    if ( index == 2 && this.paymentTypeForm.get("installment").value.id == 3 ) {
      this.states[4].state = this.states[4].state === 'collapsed' ? 'expanded' : 'collapsed';
    } else {
      this.states[index + 1].state = this.states[index + 1].state === 'collapsed' ? 'expanded' : 'collapsed';
    }
    this.logger.log( 'INDEX' , index , 200)
  }

  previous(index) {
    this.states[index].state = this.states[index].state === 'collapsed' ? 'expanded' : 'collapsed';
    if ( index == 4 ) {
      this.states[2].state = this.states[2].state === 'collapsed' ? 'expanded' : 'collapsed';
    }
    this.states[index - 1].state = this.states[index - 1].state === 'collapsed' ? 'expanded' : 'collapsed';
  }

  finish( index = null ) {
    if (index == null)
      this.changeState(3);
    else
      this.changeState(index)
  }

  changeState(index) {
    if (this.states[index].formStatusValid == true || index == 0 || this.states[index - 1].formStatusValid == true)
      {this.states[index].state = this.states[index].state === 'collapsed' ? 'expanded' : 'collapsed'; }
    if (index == 2) {
      {this.expandedData.paymentTypeData.installment = this.paymentTypeForm.get("installment").value; }
    }
  }

  selectClient(client) {
    if (client) {
      this.clientDataForm.setValue({
        email: client.email,
        name: client.name,
        surname: client.surname,
        phone: client.phoneNumber,
        language: null,
      })
    }
    this.selectedClient = client;
  }

  selectInstallment(installment) {
    this.selectedInstallment = installment;
  }

  onSelectRate(rate) {
    if (rate) {
      this.initRateForm(rate);
    }
  }

  onChangeFirstInstalmentType(event) {
    this.logger.log( 'onChangeFirstInstalmentType' , event , 200 )
    if (event) {
      this.installmentsForm.get('firstInstalmentValue').reset()
      if (event.id == 3) {
        this.installmentsForm.get('firstInstalmentValue').disable()
      } else {
        this.installmentsForm.get('firstInstalmentValue').enable()
        if ( event.id == 1 ) {

        } else if ( event.id == 2 ) {
          this.max_startInstalment = this.paymentTypeForm.get('import').value - ( this.installmentsForm.get('instalmentNumber').value - 1 )
        }
      }
    }
  }

  onChangeFirstInstalmentRecurrentType(event) {
    this.logger.log( 'onChangeFirstInstalmentRecurrentType' , event , 200 )
    if (event) {
      this.installmentsFormBis.get('firstInstalmentValue').reset()
      if (event.id == 3) {
        this.installmentsFormBis.get('firstInstalmentValue').disable()
      } else {
        this.installmentsFormBis.get('firstInstalmentValue').enable()
        if ( event.id == 1 ) {

        } else if ( event.id == 2 ) {
          
        }
      }
    }
  }

  openConfirmModal(template: TemplateRef<any>) {
    this.errorOnSendContrat = false;
    this.sentContrat = false;
    this.expandedData = {
      "clientData": {
        "email": this.clientDataForm.get("email").value,
        "name": this.clientDataForm.get("name").value,
        "surname": this.clientDataForm.get("surname").value,
        "phone": this.clientDataForm.get("phone").value,
        "language": this.clientDataForm.get("language").value,
      },
      "paymentServiceData": {
        "name": this.paymentServiceForm.get("name").value,
      },
      "paymentTypeData": {
        "installment": { "id": this.selectedInstallment.id, "name": this.selectedInstallment.name, "value": this.selectedInstallment.value },
      },
      "installmentData": {
        "import": this.paymentTypeForm.get("import").value,
      },
    }
    if (this.selectedInstallment.id == 2) {
      this.expandedData.installmentData = {
        "import": this.paymentTypeForm.get("import").value,
        "profile": this.installmentsForm.get("profile").value,
        "instalmentNumber": this.installmentsForm.get("instalmentNumber").value,
        "instalmentFrequency": this.installmentsForm.get("instalmentFrequency").value,
        "instalmentPaymentDay": this.installmentsForm.get("instalmentPaymentDay").value,
        "startDelay": this.installmentsForm.get("startDelay").value,
        "expirationType": this.installmentsForm.get("expirationType").value,
        "firstInstalmentType": this.installmentsForm.get("firstInstalmentType").value,
        "firstInstalmentValue": this.installmentsForm.get("firstInstalmentValue").value,
      }
    } else if (this.selectedInstallment.id == 3) {
      this.expandedData.installmentData = {
        "import": this.paymentTypeForm.get("import").value,
        "instalmentFrequency": this.installmentsFormBis.get("instalmentFrequency").value,
        "schedulingPaymentDay": this.installmentsFormBis.get("schedulingPaymentDay").value,
        "startDelay": this.installmentsFormBis.get("startDelay").value,
        "expirationType": this.installmentsFormBis.get("expirationType").value,
        "firstInstalmentType": this.installmentsFormBis.get("firstInstalmentType").value,
        "firstInstalmentValue": this.installmentsFormBis.get("firstInstalmentValue").value,
      }
    }
    this.modalRef = this.modalService.show(template, Object.assign({ ignoreBackdropClick: true , keyboard: false }, { class: 'confirm-dialog-contrat modal-lg' }));
  }

  async getMerchantDetail(idMerchant) {
    // this.userService.getUserDetails(idMerchant).subscribe((res: any)=>{
    //   console.log( 'getMerchantDetail' , res )
    // })
    let res = await this.sellerService.getSellerDetails( idMerchant ).toPromise();
    if( res ) {
      this.merchantDetail = res;
    }
  }

  getExternalSaleData( token ) {
    swal.showLoading();
    this.appService.getElement( '/gestionale/vendita/new/' + this.extSaleToken ).subscribe((response)=>{
      this.extSaleData = response
    
      if( !this.extSaleData.success && this.extSaleData.success != false ) {
        this.validExtSale = true
        this.showError = false;
        this.prepareFillForm( this.extSaleData )
        this.fillForm()
      } else if (this.extSaleData.success == false) {
        this.validExtSale = false
        this.showError = true;
        // if( this.extSaleData.code == '0028' ) {
        //   this.showError0028 = true
        //   this.showError0029 = false
        // } else if( this.extSaleData.code == '0029' ) {
        //   this.showError0029 = true
        //   this.showError0028 = false
        // }
      }

    })

    swal.close()
  }

  getRedirectUrl(baseUrl, result, idSale = null, idInstalment = null, amount = null) {
    let url = baseUrl;
    if (url.lastIndexOf('?') > url.lastIndexOf('/')) {
      url += '&';
    } else {
      url += '?';
    }
    url += 'idVendita=' + idSale;
    url += '&idRata=' + idInstalment;
    url += '&importo=' + amount;
    url += '&esito=' + result;
    return url;
  }

  prepareFillForm( saleData ) {
    this.localRequest = {
      "servizio": saleData.body.servizio,
      "importo": saleData.body.importo,
      "cognome": saleData.body.cognome,
      "nome": saleData.body.nome,
      "mail": saleData.body.mail,
      "cellulare": saleData.body.cellulare ? saleData.body.cellulare : '' ,
      "gestionale1": saleData.body.gestionale1 ? saleData.body.gestionale1 : "Campo libero 1",
      "gestionale2": saleData.body.gestionale2 ? saleData.body.gestionale2 :"Campo libero 2",
      "gestionale3": saleData.body.gestionale3 ? saleData.body.gestionale3 :"Campo libero 3",
      "urlCallbackOK": saleData.body.urlCallbackOK ? saleData.body.urlCallbackOK : "http://my-frontend/vendita/OK",
      "urlCallbackKO": saleData.body.urlCallbackKO ? saleData.body.urlCallbackKO : "http://my-frontend/vendita/KO",
      "urlServerToServer": saleData.body.urlServerToServer ? saleData.body.urlServerToServer : "http://my-server.api/vendita"
    };
    if( this.isVenditaSingola( saleData ) ) {
      // console.log( 'isVenditaSingola' , this.isVenditaSingola(saleData) )
    } else {
      this.localRequest.numeroRate = saleData.body.numeroRate
      this.localRequest.numeroRateModificabile = saleData.body.numeroRateModificabile
      this.isRateModificabile = saleData.body.numeroRateModificabile
      this.localRequest.primaRataModificabile = saleData.body.primaRataModificabile
      this.isPrimaRataModificabile = saleData.body.primaRataModificabile
      if( saleData.body.primaRataModificabile ) {
        this.localRequest.tipoPrimaRata = this.firstInstalmentTypes[1]
      } else {
        this.localRequest.tipoPrimaRata = this.firstInstalmentTypes[2]
      }
    }
    this.extSaleRedirectUrl = this.getRedirectUrl(saleData.body.urlCallbackKO, 'KO');
  }

  fillForm() {
    this.clientDataForm.setValue({
      name : this.localRequest.nome ? this.localRequest.nome : '' ,
      surname : this.localRequest.cognome ? this.localRequest.cognome : '' ,
      phone : this.localRequest.cellulare ? this.localRequest.cellulare : '' ,
      email : this.localRequest.mail ? this.localRequest.mail : '' ,
      language : 'IT'
    })
    this.onSubmitClientData()

    this.paymentServiceForm.setValue({
      name : this.localRequest.servizio ? this.localRequest.servizio : ''
    })
    this.onSubmitPaymentService()
    this.paymentServiceForm.controls.name.disabled

    this.paymentTypeForm.setValue({
      import : this.localRequest.importo ? this.localRequest.importo : '' ,
      installment : this.localRequest.numeroRate ? 'RATEIZZATO' : 'SINGOLO' 
    })
    this.selectedInstallment = this.ratesTypes.find(x => x.name === this.paymentTypeForm.get('installment').value );
    this.paymentTypeForm.controls['installment'].disable()
    this.onSubmitPaymentType()
    this.checkPaymentServiceForm()
    
    // this.onSubmitInstallmentData()

    if ( !this.isVenditaSingola( this.extSaleData ) ) {
      this.states[3].show = true;
      this.next(3)
      this.installmentsForm.setValue({
        profile : null ,
        instalmentNumber : this.localRequest.numeroRate, 
        instalmentFrequency : this.frequencies[0],
        startDelay : 1,
        expirationType : this.expirationTypes[0],
        firstInstalmentType : this.localRequest.tipoPrimaRata ,
        firstInstalmentValue : '',
      })
      this.installmentsForm.controls['profile'].disable()
      this.installmentsForm.controls['instalmentFrequency'].setValue(this.frequencies[0]);
      this.installmentsForm.controls['firstInstalmentType'].patchValue(this.localRequest.tipoPrimaRata , { emitEvent: true });
      this.onChangeFirstInstalmentType( this.installmentsForm.get('firstInstalmentType').value )
      this.installmentsForm.controls['instalmentFrequency'].disable()
      if( !this.localRequest.primaRataModificabile ) {
        this.installmentsForm.controls['firstInstalmentType'].disable()
      }

      if( this.localRequest.primaRataModificabile ) {
        // console.log('prima rata modificabile')
      } else {
        // console.log('prima rata non modificabile')
      }
      if( this.localRequest.numeroRateModificabile ) {
        // console.log('numero rate modificabile')
        this.editInstalmentNumber = false
      } else {
        // console.log('numero rate non modificabili')
        this.editInstalmentNumber = true
      }
      this.onSubmitInstallmentData()

      /**
       * Commentati perchè il servizio di calcolo rate necessita di autenticazione 
       * */ 
      // this.onChangePaymentTypeForm();
      this.onChangeInstallmentForm();

    }

  }

  isVenditaSingola( saleData ) {
    // console.log( 'isVenditaSingola' , saleData )
    if( saleData.body.numeroRate ){
      return false
    } else {
      return true
    }
  }

  finalizeExternaleSale( token : string , responseStatus , callback = null , callbackVar = null ) {
    this.externalService.finalizeExternalSale( token , responseStatus).subscribe((response) => {
      // console.log('finalizzo la creazione della vendita comunicando l\'esito al server richiedente' , response )
      callback(callbackVar)
    })
  }

  navigateToPayment( instalmentId ) {
    // console.log( 'going to first-instalment...' )
    // this.router.navigateByUrl(path)
    window.location.href = instalmentId
  }

  checkFirstInstalmentValue( event ) {
    var _val = event.target.value
    this.logger.log( 'maxLenght' , _val.length , 200 )
    if( this.installmentsForm.get('firstInstalmentType').value.id == 1 ) {
      this.max_startInstalment = 100 - ( (100 / this.paymentTypeForm.get('import').value) * ( (this.installmentsForm.get('instalmentNumber').value - 1)/2 ) )
      if( event.target.value > this.max_startInstalment ) {

      }
    } else if( this.installmentsForm.get('firstInstalmentType').value.id == 2 ) {
      this.max_startInstalment = this.paymentTypeForm.get('import').value - ( (this.installmentsForm.get('instalmentNumber').value - 1)/2 )
    }

    if( event.target.value >= this.max_startInstalment ) {
      this.installmentsForm.controls.firstInstalmentValue.setErrors({'incorrect' : true})
      this.installmentsForm.controls.firstInstalmentValue.setValidators([Validators.required, Validators.min(1) , Validators.max(this.max_startInstalment)])
      this.showError0030 = true
      if( this._err == true ) {
        _val = _val.substring(0, _val.length - 1 )
        event.target.value = Number(_val)
      }
      this._err = true
    } else {
      this._err = false
      if( this.installmentsForm.controls.firstInstalmentValue.errors ) {
        this.installmentsForm.controls['firstInstalmentValue'].setErrors(null);
      }
      this.showError0030 = false
    }
    
  }

  setLanguageClient( countryCode: string ) {
    this.clientDataForm.controls.language.patchValue( countryCode );
  }

}
