<div class="container wrapper" [ngClass]="{'blank_layout' : isExternalSale }">
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="m-bottom-0 text-center" [ngClass]="{'float-left' : !isExternalSale}">{{'new-sale.new-sale' | translate}}</h3><!-- float-left  -->
      <button type="button" [routerLink]="['..']" *ngIf="!isExternalSale" class="btn btn-danger mg-12 float-right button-style">{{'general.cancel' | translate}}</button>
    </div>
  </div>

  <div class="col-md-12" *ngIf="(isExternalSale && validExtSale) || !isExternalSale">
    <div class="row bottom-30">
      <div class="timeline-centered">
        <article class="timeline-entry">
          <div class="timeline-entry-inner">
            <div class="timeline-icon " [ngClass]="{'empty':!clientDataForm.valid,'bg-infoo':clientDataForm.valid}">
              1
            </div>
            <div class="card ml-60">
              <div class="panel-title">
                <div class="col-md-12">
                  <div class="row space-between">
                    <p class="panel-title-title">{{'new-sale.client-date' | translate}}</p>
                    <div *ngIf="states[0].state == 'expanded'">
                      <button *ngIf="!states[0].formStatusValid" type="button" class="btn blue-bg btn-primary mx-12  "
                        (click)="onSubmitClientData()">{{'general.next'| translate}}</button>
                      <button *ngIf="states[0].formStatusValid" type="button" class="btn btn-danger mx-12" (click)="changeState(0)">
                        {{'general.close'| translate}}</button>
                    </div>
                    <div *ngIf="states[0].state == 'collapsed'">
                      <i (click)="changeState(0)" class="fa fa-angle-down panel-angle" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="row" *ngIf="states[0].state == 'collapsed'">
                    <span class="panel-title-body">{{clientDataForm.get('name').value}}
                      {{clientDataForm.get('surname').value}} <b>{{clientDataForm.get('email').value}}</b></span>
                  </div>
                </div>
              </div>
              <div class="card-body " [@toggleFirstPanel]=states[0].state>
                <div *ngIf="states[0].state == 'expanded'">
                  <form [formGroup]="clientDataForm" (ngSubmit)="onSubmitClientData()">
                    <div class="row">

                      <div class="col-md-6">
                        <div class=" has-float-label">
                          <input class="custom-float-label-input" formControlName="name" type="text" placeholder=" " id="name" [readonly]="isExternalSale">
                          <label class="label custom-label" for="name"> {{'general.name'| translate}} </label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class=" has-float-label">
                          <input class="custom-float-label-input" formControlName="surname" type="text" placeholder=" "
                            id="surname" [readonly]="isExternalSale">
                          <label class="label custom-label" for="surname"> {{'general.surname'| translate}} </label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="has-float-label">
                          <input class="custom-float-label-input"
                            formControlName="email" type="email" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" placeholder=" "
                            id="email" [readonly]="isExternalSale">
                          <label class="label custom-label" for="email"> {{'general.email'| translate}} </label>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class=" has-float-label">
                          <input class="custom-float-label-input" formControlName="phone" type="text" placeholder=" "
                            id="phone" [readonly]="isExternalSale">
                          <label class="label custom-label" for="phone"> {{'general.celular'| translate}} </label>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="has-float-label">
                          <ng-select class="custom" [items]="langList" placeholder="Seleziona la lingua del cliente" formControlName="language" bindLabel="text" bindValue="code">
                          </ng-select>
                        </div>
                      </div>
                      
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="timeline-entry">
          <div class="timeline-entry-inner">
            <div class="timeline-icon " [ngClass]="{'empty':!paymentServiceForm.valid,'bg-infoo':paymentServiceForm.valid}">
              2
            </div>
            <div class="card ml-60">
              <div class="panel-title">
                <div class="col-md-12">
                  <div class="row space-between">
                    <p class="panel-title-title">{{'new-sale.pay-service' | translate}}</p>
                    <div *ngIf="states[1].state == 'expanded'">
                      <button *ngIf="!states[1].formStatusValid" type="button" class="btn blue-bg btn-primary mx-12  "
                        (click)="onSubmitPaymentService(1)">{{'general.next'| translate}}</button>
                      <button *ngIf="states[1].formStatusValid" type="button" class="btn btn-danger mx-12" (click)="changeState(1)">
                        {{'general.close'| translate}}</button>
                    </div>
                    <div *ngIf="states[1].state == 'collapsed'">
                      <i *ngIf="states[0].formStatusValid" (click)="changeState(1)" class="fa fa-angle-down panel-angle"
                        aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="row" *ngIf="states[1].state == 'collapsed'">
                    <span class="panel-title-body">{{paymentServiceForm.get('name').value}}</span>
                  </div>
                </div>
              </div>
              <div class="card-body " [@toggleSecondPanel]=states[1].state>
                <div *ngIf="states[1].state == 'expanded'">
                  <form [formGroup]="paymentServiceForm" (ngSubmit)="onSubmitPaymentService()">
                    <div class="row">
                      <div class="col-md-6">
                        <div class=" has-float-label">
                          <input class="custom-float-label-input" formControlName="name" type="text" placeholder=" " id="name"
                            [readonly]="isExternalSale">
                          <label class="label custom-label" for="name"> {{'general.name'| translate}} </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="timeline-entry">
          <div class="timeline-entry-inner">
            <div class="timeline-icon " [ngClass]="{'empty':!paymentTypeForm.valid,'bg-infoo':paymentTypeForm.valid}">
              3
            </div>
            <div class="card ml-60">
              <div class="panel-title">
                <div class="col-md-12">
                  <div class="row space-between">
                    <p class="panel-title-title">{{'new-sale.payment-type' | translate}}</p>
                    <div *ngIf="states[2].state == 'expanded'">
                      <button *ngIf="!states[2].formStatusValid" type="button" class="btn blue-bg btn-primary mx-12  "
                        (click)="onSubmitPaymentType()">{{'general.next'| translate}}</button>
                      <button *ngIf="states[2].formStatusValid" type="button" class="btn btn-danger mx-12" (click)="changeState(2)">
                        {{'general.close'| translate}}</button>
                    </div>
                    <div *ngIf="states[2].state == 'collapsed'">
                      <i *ngIf="states[1].formStatusValid" (click)="changeState(2)" class="fa fa-angle-down panel-angle"
                        aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="row" *ngIf="states[2].state == 'collapsed' && expandedData.paymentTypeData.installment">
                    <span class="panel-title-body">{{expandedData.paymentTypeData.installment.name}}</span>
                  </div>
                </div>
              </div>
              <div class="card-body" [@toggleThirdPanel]=states[2].state>
                <div *ngIf="states[2].state == 'expanded'">
                  <form [formGroup]="paymentTypeForm" (ngSubmit)="onSubmitPaymentType()">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="has-float-label">
                          <input class="custom-float-label-input" formControlName="import" type="number" min="0"
                            placeholder=" " id="import" [readonly]="isExternalSale">
                          <label class="label custom-label" for="import">{{'new-sale.import'| translate}}</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="has-float-label">
                          <ng-select class="custom" placeholder="Rate" formControlName="installment" bindLabel="name"
                            (change)="selectInstallment($event)">
                            <ng-option *ngFor="let ratesType of ratesTypes" [value]="ratesType">{{ratesType.name}}</ng-option>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="timeline-entry" *ngIf="states[3].show">
          <div class="timeline-entry-inner">
            <div class="timeline-icon " [ngClass]="{'empty':!installmentsForm.valid,'bg-infoo':installmentsForm.valid}">
              4
            </div>
            <div class="card ml-60">
              <div class="panel-title">
                <div class="col-md-12">
                  <div class="row space-between">
                    <p class="panel-title-title">{{'new-sale.title-installment' | translate}}</p>
                    <div *ngIf="states[3].state == 'expanded'">
                      <button type="button" class="btn blue-bg btn-primary  mx-12 succeess " (click)="onSubmitInstallmentData(3)">
                        {{'general.save'| translate}}</button>
                    </div>
                    <div *ngIf="states[3].state == 'collapsed'">
                      <i *ngIf="states[2].formStatusValid" (click)="changeState(3)" class="fa fa-angle-down panel-angle"
                        aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="row" *ngIf="states[3].state == 'collapsed'">
                    <span class="panel-title-body">{{selectedRate?.description}}</span>
                  </div>
                </div>
              </div>
              <div class="card-body " [@toggleFourthPanel]=states[3].state>
                <div [hidden]="states[3].state != 'expanded'">
                  <div>
                    <i class="fa fa-info-circle"></i> {{'new-sale.expiration-type-message' | translate}}
                  </div>
                  <form [formGroup]="installmentsForm" (ngSubmit)="onSubmitInstallmentData()">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{'new-sale.profile'| translate}} " formControlName="profile"
                            (change)="onSelectRate($event)">
                            <ng-option *ngFor="let rate of rates" [value]="rate">{{rate.description}}</ng-option>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class=" has-float-label">
                          <!-- <ng-select class="custom" placeholder="{{ 'new-sale.number'| translate }} " formControlName="instalmentNumber">
                            <ng-option *ngFor="let instalmentNumber of instalmentNumbers" [value]="instalmentNumber.number">{{day.number}}</ng-option>
                          </ng-select> -->
                          <input type="number" id="instalmentNumber" class="custom-float-label-input" min="2" max="36" placeholder=" " [readonly]="!isRateModificabile" formControlName="instalmentNumber">
                          <label class="label custom-label in-s" for="instalmentNumber"> {{ 'new-sale.number'| translate }} </label>
                        </div>
                        <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{'new-sale.frequency'| translate}}" bindLabel="name" formControlName="instalmentFrequency">
                            <ng-option *ngFor="let frequency of frequencies" [value]="frequency">{{frequency.name}}</ng-option>
                          </ng-select>
                        </div>
                        <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{'new-sale.instalment-payment-day'| translate}}" formControlName="instalmentPaymentDay">
                            <ng-option *ngFor="let day of days" [value]="day.number">{{day.number}}</ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="has-float-label">
                          <input class="custom-float-label-input" formControlName="startDelay" type="number" min="0"
                            placeholder=" " id="start" [readonly]="isExternalSale">
                          <label class="label custom-label" for="start">{{'new-sale.start'| translate}} </label>
                        </div>
                        <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{ 'new-sale.expiry' | translate }}"
                            formControlName="expirationType" bindLabel="name" [clearable]="true">
                            <ng-option *ngFor="let type of expirationTypes" [value]="type">{{type.name}}</ng-option>
                          </ng-select>
                        </div>
                        <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{ 'profile.first_installment' | translate }}"
                            formControlName="firstInstalmentType" (change)="onChangeFirstInstalmentType($event)" bindLabel="name" [clearable]="true">
                            <ng-option *ngFor="let type of firstInstalmentTypes" [value]="type">{{type.name}}</ng-option>
                          </ng-select>
                        </div>
                        <div *ngIf="firstInstalmentType">
                          <div class=" has-float-label" *ngIf="firstInstalmentType.id == 1">
                            <input pattern="(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)" class="custom-float-label-input"
                              formControlName="firstInstalmentValue" max="{{max_startInstalment}}" maxlength="{{max_digit}}" type="number" placeholder=" " id="start_installment" (input)="checkFirstInstalmentValue($event)">
                            <label class="label custom-label in-s" for="start_installment"> {{'rata.prima-rata' | translate}} </label>
                          </div>
                          <div class=" has-float-label" *ngIf="firstInstalmentType.id == 2">
                            <input class="custom-float-label-input" formControlName="firstInstalmentValue" type="number"
                              placeholder=" " max="{{max_startInstalment}}" maxlength="{{max_digit}}" id="start_installment" (input)="checkFirstInstalmentValue($event)">
                            <label class="label custom-label" for="start_installment"> {{'rata.valore-prima-rata' | translate}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <!-- <div style="height:250px" *ngIf="!shownTable"></div> -->
                  <div style="height:60px"></div>
                  <div class="row justify-content-center" *ngIf="shownTable">
                    <div class="col">
                      <h3 class="text-center">{{ 'new-sale.title-prospetto-rate' | translate}}</h3>
                    </div>
                    <div class="w-100"></div>
                    <div class="col">
                      <table class="table table-scroll" style="margin-left: auto; margin-right: auto;" *ngIf="shownTable">
                        <thead class="s-thead">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" class="capital">{{'new-sale.import'| translate}}</th>
                            <th scope="col" class="capital">{{'new-sale.expiry'| translate}}</th>
                          </tr>
                        </thead>
                        <tbody class="s-tbody">
                          <tr *ngFor="let month of monthsCalculations">
                            <td class="no-border">{{month.nrMonth}}</td>
                            <td class="no-border">{{month.value}}</td>
                            <td class="no-border">{{month.date | date:'dd/MM/yyyy'}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row justify-content-center" *ngIf="showError0030">
                    <div class="col">
                      <h3 class="text-center" [innerHTML]="'new-sale.invalid-instalment-amount' | translate"></h3>
                      <p class="text-center error0030 pulse" style="color:#666666;">
                        <span popover="{{ 'new-sale.invalid-instalment-amount-tip' | translate }}" popoverTitle="" [outsideClick]="true"><i class="fa fa-info-circle"></i></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="timeline-entry" *ngIf="states[4].show">
          <div class="timeline-entry-inner">
            <div class="timeline-icon " [ngClass]="{'empty':!installmentsFormBis.valid,'bg-infoo':installmentsFormBis.valid}">
              4
            </div>
            <div class="card ml-60">
              <div class="panel-title">
                <div class="col-md-12">
                  <div class="row space-between">
                    <p class="panel-title-title">{{'new-sale.title-form-ricorrenze' | translate}}</p>
                    <div *ngIf="states[4].state == 'expanded'">
                      <button type="button" class="btn blue-bg btn-primary  mx-12 succeess " (click)="onSubmitRicorrenzaData(3)">{{'general.save'| translate}}</button>
                    </div>
                    <div *ngIf="states[4].state == 'collapsed'">
                      <i *ngIf="states[2].formStatusValid" (click)="changeState(4)" class="fa fa-angle-down panel-angle" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="row" *ngIf="states[4].state == 'collapsed'">
                    <span class="panel-title-body">{{selectedRate?.description}}</span>
                  </div>
                </div>
              </div>
              <div class="card-body " [@toggleFifthPanel]=states[4].state>
                <div [hidden]="states[4].state != 'expanded'">
                  <div>
                    <i class="fa fa-info-circle"></i> {{'new-sale.expiration-type-message' | translate}}
                  </div>
                  <form [formGroup]="installmentsFormBis" (ngSubmit)="onSubmitRicorrenzaData()">
                    <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-6">
                        <div class="has-float-label">
                          <input class="custom-float-label-input" formControlName="startDelay" type="number" min="0"
                            placeholder=" " id="start" [readonly]="isExternalSale">
                          <label class="label custom-label" for="start">{{'new-sale.start'| translate}} </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <!-- <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{'new-sale.profile'| translate}} " formControlName="profile"
                            (change)="onSelectRate($event)">
                            <ng-option *ngFor="let rate of rates" [value]="rate">{{rate.description}}</ng-option>
                          </ng-select>
                        </div> -->
                        <!-- <div class=" has-float-label">
                          <ng-select class="custom" placeholder="{{ 'new-sale.number'| translate }} " formControlName="instalmentNumber">
                            <ng-option *ngFor="let instalmentNumber of instalmentNumbers" [value]="instalmentNumber.number">{{day.number}}</ng-option>
                          </ng-select>
                        </div> -->
                        <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{'new-sale.frequency'| translate}}" formControlName="instalmentFrequency">
                            <ng-option *ngFor="let frequency of frequencies" [value]="frequency">{{frequency.name}}</ng-option>
                          </ng-select>
                        </div>
                        <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{'new-sale.scheduling-payment-day'| translate}}" formControlName="schedulingPaymentDay">
                            <ng-option *ngFor="let schedulingPaymentDay of schedulingPaymentDays" [value]="schedulingPaymentDay">{{schedulingPaymentDay.name}}</ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{ 'new-sale.expiry' | translate }}"
                            formControlName="expirationType" bindLabel="name" [clearable]="true">
                            <ng-option *ngFor="let type of expirationTypes" [value]="type">{{type.name}}</ng-option>
                          </ng-select>
                        </div>
                        <!-- <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{ 'profile.first_installment' | translate }}"
                            formControlName="firstInstalmentType" (change)="onChangeFirstInstalmentType($event)">
                            <ng-option *ngFor="let type of firstInstalmentTypes" [value]="type">{{type.name}}</ng-option>
                          </ng-select>
                        </div> -->
                        <!-- <div *ngIf="firstInstalmentType">
                          <div class=" has-float-label" *ngIf="firstInstalmentType.id == 1">
                            <input pattern="(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)" class="custom-float-label-input"
                              formControlName="firstInstalmentValue" type="number" placeholder=" " id="start_installment">
                            <label class="label custom-label in-s" for="start_installment"> {{'rata.prima-rata' | translate}} </label>
                          </div> -->
                          <!-- <div class=" has-float-label" *ngIf="firstInstalmentType.id == 2">
                            <input class="custom-float-label-input" formControlName="firstInstalmentValue" type="number"
                              placeholder=" " id="start_installment">
                            <label class="label custom-label" for="start_installment"> {{'rata.valore-prima-rata' | translate}}
                            </label>
                          </div>
                        </div> -->
                        <div class="has-float-label">
                          <ng-select class="custom" placeholder="{{ 'profile.first_installment' | translate }}"
                            formControlName="firstInstalmentType" (change)="onChangeFirstInstalmentRecurrentType($event)" bindLabel="name" [clearable]="true">
                            <ng-option *ngFor="let type of firstInstalmentRecurrentTypes" [value]="type">{{type.name}}</ng-option>
                          </ng-select>
                        </div>
                        <div *ngIf="firstInstalmentRecurrentType">
                          <div class=" has-float-label" *ngIf="firstInstalmentRecurrentType.id == 2">
                            <input class="custom-float-label-input" formControlName="firstInstalmentValue" type="number"
                              placeholder=" " maxlength="{{max_digit}}" id="start_installment_recurrent">
                            <label class="label custom-label" for="start_installment_recurrent"> {{'rata.valore-prima-rata' | translate}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <!-- <div style="height:250px" *ngIf="!shownTable"></div> -->
                  <div style="height:60px"></div>
                  <table class="table table-scroll" *ngIf="shownTable">
                    <thead class="s-thead">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col" class="capital">{{'new-sale.import'| translate}}</th>
                        <th scope="col" class="capital">{{'new-sale.expiry'| translate}}</th>
                      </tr>
                    </thead>
                    <tbody class="s-tbody">
                      <tr *ngFor="let month of monthsCalculations">
                        <td class="no-border">{{month.nrMonth}}</td>
                        <td class="no-border">{{month.value}}</td>
                        <td class="no-border">{{month.date | date:'dd/MM/yyyy'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="row">
      <button [disabled]="!clientDataForm.valid || !paymentServiceForm.valid || !paymentTypeForm.valid || (!installmentsForm.valid && states[3].show ) || (!installmentsFormBis.valid && states[4].show ) || showError0030"
        type="button" (click)="openConfirmModal(template)" class="btn continue-button mb-30 btn-success save60">
        {{'general.continue'| translate}}
      </button>
    </div>
    <div class="row" *ngIf="validExtSale">
      <a [href]="extSaleRedirectUrl" class="btn continue-button mb-30 btn-danger save60" style="margin-top: 20px">{{'general.discard' | translate }}</a>
    </div>
  </div>

  <div class="row row-errors align-items-center justify-content-center">
    <div class="col-sm-6" *ngIf="!validExtSale && extSaleData">
      <!-- START card-->
      <div class="card card-flat text-center">
        <div class="card-header text-center bg-secondary ">
          <a href="#">
            <img class="block-center rounded logo-padded" src="assets/img/service-pay-logo.png" alt="Image" />
          </a>
        </div>
        <div class="card-body width-450 margin-auto">
          <div class="row icon-row">
            <em  class="icon-close text-danger mr-2 fa-lg"></em>
          </div>
          <!-- <p class="text-center py-2 text-danger secondary-text-color">{{'user.sale-notactivated' | translate}}</p> -->
          <p class="text-center py-2 text-danger secondary-text-color">{{ 'external.error' + extSaleData.code | translate }}</p>
        </div>
        
      </div>
      <!-- END card-->
      <!-- <h3 class="text-center" *ngIf="showError">{{ 'external.error' + extSaleData.code | translate }}</h3> -->
    </div>
  </div>

</div>

<ng-template #template>
  <div class="modal-header header-modal ">
    <h4 class="modal-title center-title">{{'new-sale.new-sale' | translate}}</h4>
    <!-- <button type="button" class="close close-modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body center" *ngIf="!sentContrat && !errorOnSendContrat">
    <h3 class="body-title-modal">{{'new-sale.send-contract' | translate}}</h3>
    <p class="center">
      <span>{{expandedData.clientData.name + " " + expandedData.clientData.surname}}</span> - <b>{{expandedData.clientData.email}}</b><br>
      <span>{{expandedData.paymentServiceData.name}}</span><br>
      <span>{{expandedData.paymentTypeData.installment.name}}</span><br>
      <span *ngIf="expandedData.paymentTypeData.installment.name == 'RATEIZZATO' ">{{expandedData.installmentData.instalmentFrequency.name}} - {{expandedData.installmentData.instalmentNumber}} rate </span><br>
    </p>
    <div class="buttons-modal">
      <div class="row center-cnt">
        <button type="button" (click)="sendContrat()" class="btn mw140 btn-success  continue-button">
          {{'new-sale.send-contrat' | translate}}
        </button>
      </div>
      <div class="row center-cnt">
        <button type="button" (click)="modalRef.hide()" class="btn mw140 blue-bg btn-primary  continue-button">
          {{'new-sale.modify-data' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body center" *ngIf="sentContrat || errorOnSendContrat">
    <div class="sentContrat">
      <h3 class="body-title-modal-s" *ngIf="sentContrat"> 
        {{'sale.sent-contrat-success' | translate}}
      </h3>
      <h3 class="body-title-modal-s" *ngIf="errorOnSendContrat"> 
        {{'sale.sent-contrat-error' | translate}}
      </h3>
      <div class="row justify-content-sm-center" *ngIf="sentContrat && merchantDetail.linkFirstPayment">
        <div class="col-sm-8">
          <p>{{'sale.click' | translate}} <a href="{{linkFirstInstallment}}" target="_blank">{{'sale.here' | translate}}</a> {{'sale.first-payment-qrcode' | translate}}</p>
        </div>
      </div>
      <div *ngIf="sentContrat && merchantDetail.linkFirstPayment"  class="col-sm-auto" >
        <qr-code [value]="linkFirstInstallment" [size]="200"></qr-code>
      </div>
      <span *ngIf="errorOnSendContrat" class="ticket error">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
      <div *ngIf="!merchantDetail.linkFirstPayment && sentContrat">
        <p>{{'sale.first-payment-reminder' | translate}}</p>
      </div>
      <div class="row success-button">
        <button type="button" *ngIf="sentContrat"  (click)="modalRef.hide()" [routerLink]="['..']" class="btn mw140 blue-bg btn-primary mg-12 continue-button">{{'new-sale.confirmed' | translate}}</button>
        <button type="button" *ngIf="errorOnSendContrat"  (click)="modalRef.hide()" class="btn mw140 blue-bg btn-primary mg-12 continue-button">{{'general.close' | translate}}</button>
      </div>
    </div>
  </div>
</ng-template>
