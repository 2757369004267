<div class="wrapper page container">

  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'sale.title' | translate}}</h3>
      <button *ngIf="profile!='SUPERADMIN' && profile!='RESELLER' && !merchantDetail.softwareHouse && merchantDetail.tos_accepted && merchantDetail.state == 'ACTIVE'" type="button" class="btn blue-bg mg-12 header-button float-right" routerLink="./new">
        {{'sale.new_sale' | translate}}</button>
    </div>
  </div>
  <div class="row border-bottom">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link tab" [ngClass]="{'active':activePage == 'sa','list-selected-page':activePage == 'sa'}"
          (click)="setActivePage('sa')">{{'sale.sales_analysis' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link tab" [ngClass]="{'active':activePage == 'ra','list-selected-page':activePage == 'ra'}"
          (click)="setActivePage('ra')">{{'sale.rate_analysis' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link tab" [ngClass]="{'active':activePage == 're','list-selected-page':activePage == 're'}"
          (click)="setActivePage('re')">{{'sale.refunds_debits_analysis' | translate}}</a>
      </li>
    </ul>
  </div>
  <div class="pt-30">
    <app-sale-analysis *ngIf="activePage == 'sa'" (deletedChip)="clearSelectedUser()"></app-sale-analysis>
    <app-rate-analysis *ngIf="activePage == 'ra'" (deletedChip)="clearSelectedUser()"></app-rate-analysis>
    <app-refund-analysis *ngIf="activePage == 're'" (deletedChip)="clearSelectedUser()"></app-refund-analysis>
  </div>
  
  <div id="sidebar-wrapper" *ngIf="profile=='SUPERADMIN' || profile=='RESELLER'">
    <app-sidebar-users [users]="users" (selectedUser)="selectedUser($event)"></app-sidebar-users>
  </div>

</div>
